// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/root/src/models/useCampaign';
import model_2 from '/root/src/models/useConversion';
import model_3 from '/root/src/models/usePerformance';
import model_4 from '/root/src/models/useProduct';
import model_5 from '/root/src/models/useRevenue';
import model_7 from '/root/src/.umi-production/plugin-initialState/@@initialState';
import model_6 from '/root/src/models/useUser';

export const models = {
model_1: { namespace: 'useCampaign', model: model_1 },
model_2: { namespace: 'useConversion', model: model_2 },
model_3: { namespace: 'usePerformance', model: model_3 },
model_4: { namespace: 'useProduct', model: model_4 },
model_5: { namespace: 'useRevenue', model: model_5 },
model_7: { namespace: '@@initialState', model: model_7 },
model_6: { namespace: 'useUser', model: model_6 },
} as const
