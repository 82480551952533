import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React from 'react';

const DEFAULT_LANGS = [
  {
    lang: 'en-US',
    label: 'English',
  },
  {
    lang: 'zh-TW',
    label: '繁體中文',
  },
  {
    lang: 'zh-CN',
    label: '简体中文',
  },
];

const LangDrowdown: React.FC<{
  onUpdateLocale: (lang: string, realReload?: boolean) => void;
}> = ({ onUpdateLocale }) => {
  const itemClick = (item: MenuInfo) => {
    onUpdateLocale(item?.key, false);
  };

  const menu = (
    <Menu onClick={itemClick}>
      {DEFAULT_LANGS.map((lang) => (
        <Menu.Item key={lang?.lang}>
          <Space>{lang.label}</Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <span style={{ cursor: 'pointer' }}>
        <GlobalOutlined />
      </span>
    </Dropdown>
  );
};

export default LangDrowdown;
