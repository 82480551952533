// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","hideInMenu":true,"parentId":"ant-design-pro-layout","id":"1"},"2":{"path":"/report","name":"report","layout":false,"hideInMenu":true,"id":"2"},"3":{"path":"/login","name":"login","layout":false,"hideInMenu":true,"id":"3"},"4":{"path":"/resetpassword","layout":false,"id":"4"},"5":{"name":"overview","path":"/overview","icon":"AppstoreFilled","parentId":"ant-design-pro-layout","id":"5"},"6":{"path":"/overview/traffic","name":"traffic","icon":"AppstoreFilled","parentId":"5","id":"6"},"7":{"path":"/overview/conversion","name":"conversion","icon":"FunnelPlotFilled","parentId":"5","id":"7"},"8":{"path":"/overview/revenue","name":"revenue","icon":"StockOutlined","parentId":"5","id":"8"},"9":{"name":"analysis","path":"/analysis","icon":"AppstoreFilled","parentId":"ant-design-pro-layout","id":"9"},"10":{"path":"/analysis/campaign","name":"campaign.performance","icon":"BorderLeftOutlined","parentId":"9","id":"10"},"11":{"name":"profile","path":"/profile","icon":"SettingFilled","parentId":"ant-design-pro-layout","id":"11"},"12":{"path":"/profile","name":"personal","icon":"SettingFilled","parentId":"11","id":"12"},"13":{"path":"*","layout":false,"id":"13"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Redirect__index" */'@/pages/Redirect/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Report__index" */'@/pages/Report/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Login__index" */'@/pages/Login/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__ResetPassword__index" */'@/pages/ResetPassword/index.tsx')),
'5': React.lazy(() => import( './EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Traffic__index" */'@/pages/Traffic/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__Conversion__index" */'@/pages/Conversion/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Revenue__index" */'@/pages/Revenue/index.tsx')),
'9': React.lazy(() => import( './EmptyRoute')),
'10': React.lazy(() => import(/* webpackChunkName: "p__Campaign__index" */'@/pages/Campaign/index.tsx')),
'11': React.lazy(() => import( './EmptyRoute')),
'12': React.lazy(() => import(/* webpackChunkName: "p__Profile__index" */'@/pages/Profile/index.tsx')),
'13': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/root/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
