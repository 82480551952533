import { getSsoUrl } from '@/utils/getSsoUrl';
import { getStorageCompanyId, setStorageCompanyId } from '@/utils/localStorage';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import React from 'react';

interface UserlogoutProps {
  (): Promise<boolean | void>;
}

interface FormatMessageProp {
  ({ id }: { id: string }): string;
}

export type GlobalHeaderRightProps = {
  onUserlogout: UserlogoutProps;
  formatMessage: FormatMessageProp;
  currentUser: {
    email: string;
  };
  companies: API.Company[];
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut: (onUserlogout: UserlogoutProps) => Promise<void> = async (onUserlogout) => {
  const isDisableRedirect = await onUserlogout();
  if (!isDisableRedirect) {
    window.location.assign(`${getSsoUrl()}/login`);
  }
};

const UserDrowdown: React.FC<GlobalHeaderRightProps> = ({
  onUserlogout,
  formatMessage,
  currentUser,
  companies,
}) => {
  const selectCompanyId = getStorageCompanyId() || companies[0].id;
  const selectCompany = companies.find((company) => company.id === selectCompanyId);
  const optionCompanied = companies.filter((company) => company?.id !== selectCompanyId);

  const companyMenu = {
    key: 'company',
    type: 'group',
    label: 'Company',
    children: optionCompanied.map((company) => ({
      key: company.id,
      label: (
        <span
          onClick={() => {
            setStorageCompanyId(company.id);
            window.location.reload();
          }}
        >
          <UserOutlined style={{ marginRight: '4px' }} />
          {company.name}
        </span>
      ),
    })),
  };

  const items: MenuProps['items'] = [
    optionCompanied?.length ? companyMenu : null,
    {
      key: 'user',
      type: 'group',
      label: currentUser?.email,
      children: [
        {
          key: 'user-logout',
          label: (
            <Space size={6} onClick={() => loginOut(onUserlogout)}>
              <LogoutOutlined />
              {formatMessage({
                id: 'common.nav.logout',
              })}
            </Space>
          ),
        },
      ],
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <span style={{ cursor: 'pointer' }}>
        <UserOutlined style={{ marginRight: '4px' }} />
        {selectCompany?.name}
      </span>
    </Dropdown>
  );
};

export default UserDrowdown;
