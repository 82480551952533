import { QuestionCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';

interface FormatMessageProp {
  ({ id }: { id: string }): string;
}

const QuestionMenu: React.FC<{
  formatMessage: FormatMessageProp;
}> = ({ formatMessage }) => {
  return (
    <Menu>
      <Menu.Item>
        <a href="https://mobagel.com/tw/help/" target="_blank" rel="noopener noreferrer">
          {formatMessage({ id: 'common.nav.contact' })}
        </a>
      </Menu.Item>
    </Menu>
  );
};

const InfoDropDwon: React.FC<{
  formatMessage: FormatMessageProp;
}> = ({ formatMessage }) => {
  return (
    <Dropdown overlay={<QuestionMenu formatMessage={formatMessage} />} placement="bottomRight">
      <span style={{ cursor: 'pointer' }}>
        <QuestionCircleOutlined />
      </span>
    </Dropdown>
  );
};

export default InfoDropDwon;
